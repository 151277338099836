import React from "react";
import { Link } from "react-router-dom";
import useWindowDimensions from "../utils/useWindowDimensions";

import saveTimeImg from "../images/save-time-pic.png";
import optimizeTaskImg from "../images/optimize-tasks-pic.png";
import fourthDivImg from "../images/fourth-div-pic.png";

import gsIcon from "../images/gs-icon.png";
import taIcon from "../images/ta-icon.png";
import daIcon from "../images/da-icon.png";
import rcIcon from "../images/rc-icon.png";

import "./Home.css";

const Home = () => {
  const { height, width } = useWindowDimensions();
  return (
    <div>
      <div className="cover_image" style={{ height: height }}>
        <span id="cover_span">
          <div id="cover_span_text_container">
            <div style={{ fontSize: "34px", fontWeight: "700" }}>
            EdataPlus
            </div>
            The MES that orchestrates people, processes, materials & machines,
            all to increase efficiency and drive throughput.
          </div>
          <div id="contactus_link">
            <Link to={"/contact"}>contact us</Link>
          </div>
        </span>
        <span id="cover_btn">
          <Link to="/contact">contact us</Link>
        </span>
      </div>

      <div id="first-div">
        <div className="text-container">
          <div className="header1">Integrated solutions</div>
          <div className="descreption">
            Every soltion for your task at hand. From Sales to Engineering we
            got your back!
          </div>
        </div>
      </div>

      <div id="second-div">
        <div className="second-div-column">
          <img src={saveTimeImg} alt="" className="second-div-img" />
          <div className="header2">Save Time</div>
          <div className="descreption">
            Save time and meet delivery targets reliably with intuitive
            purchasing, with long-lead items ahead of BOM.
          </div>
        </div>
        <div className="second-div-column">
          <img src={optimizeTaskImg} alt="" className="second-div-img" />
          <div className="header2">Optimize tasks</div>
          <div className="descreption">
            Avoid material shortages, Simplify shop-floor scheduling, Make price
            inquiries for multiple items and more.
          </div>
        </div>
      </div>

      <div id="third-div">
        <span style={{ maxWidth: "1196px", textAlign: "center" }}>
          <div className="header1">Ready for a big change?</div>
          <div className="descreption" style={{ maxWidth: "850px" }}>
            EData+ provides processes and information flows that are streamlined
            through integration solutions.
          </div>
        </span>
      </div>

      <div id="fourth-div">
        {/* <div className="fourth-div-column" id="img-col">
        <img src={fourthDivImg} alt="" style={{width:'100%', height:'100%'}} />
      </div> */}
        <div className="fourth-div-column">
          <div className="header2">Edata+ offers benfits to its customers:</div>
          <div className="grid-container">
            <div>
              <img src={gsIcon} alt="" />
              <div className="grid-title">Global Support</div>
              <div className="grid-desc">
                monitor sales pipeline, with ERP and CRM support.
              </div>
            </div>

            <div>
              <img src={taIcon} alt="" />
              <div className="grid-title">Task automation</div>
              <div className="grid-desc">
                Take CAD designs, transform them into BOMs and RFQs.
              </div>
            </div>

            <div>
              <img src={daIcon} alt="" />
              <div className="grid-title">Data analysis</div>
              <div className="grid-desc">
                Monitor and measure operations using real-time dashboards
              </div>
            </div>

            <div>
              <img src={rcIcon} alt="" />
              <div className="grid-title">Remote control</div>
              <div className="grid-desc">
                Connect 3rd-party applications, to share real-time information
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
