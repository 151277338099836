import React from "react";
import ContactForm from "../components/ContactForm";
import "./ContactUs.css";

function ContactUs() {
  return (
    <div id="contactus">
      <h1>Contact Us</h1>
      <div className="flex-container">
        <div className="flex-item" id="left-col">
          If you have any questions or inquiries about services, please fill out
          form below.
          <ContactForm />
        </div>

        {/* <div className="flex-item" id="right-col">
          <h2 style={{ margin: "0%" }}>location</h2>
          <div className="row">
            <h5>Edata plus</h5>
          </div>
          <div className="row">
            <h5>56 Maxwell St. North York. Yonge St. /Sheppard</h5>
          </div>
          <div className="row">
            <h5>647-687-xxxx</h5>
          </div>
          <br/>
          <h2 style={{ margin: "0%" }}>Office Hours</h2>

          <div style={{ display: "flex" }}>
            <h5 style={{ flex: 1 }}>Mon. - Fri.</h5>
            <h5 style={{ flex: 3 }}>8:00 am - 4:30 pm</h5>
          </div>
          <div style={{ display: "flex" }}>
            <h5 style={{ flex: 1, margin: "0%" }}>Sat.</h5>
            <h5 style={{ flex: 3, margin: "0%" }}>10:00 am - 4:30 pm</h5>
          </div>
          <div style={{ display: "flex" }}>
            <h5 style={{ flex: 1 }}>Sun.</h5>
            <h5 style={{ flex: 3 }}>Closed</h5>
          </div>
          <br/>
        </div> */}
      </div>
    </div>
  );
}

export default ContactUs;
