import { Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Products from "./Pages/Products";
import AboutUs from "./Pages/AboutUs";
import ContactUs from "./Pages/ContactUs";
import MES from "./Pages/MES"


import Navbar from "./components/Navbar";
//import Footer from "./components/Footer";

import "./App.css";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <Navbar />
      <div>
        
          <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/products" element={<Products />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/mes" element={<MES />} />
        </Routes>
        
      </div>
     <Footer />
    </div>
  );
}

export default App;
