import React from "react";
import logo from "../images/EDATA_Logo_white.png";
import { Link } from "react-router-dom";
import "./Footer.css";

function Footer() {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="columns">
          <div className="column" id="logo_column">
            <img src={logo} id="footer_logo" alt="logo-white" />
          </div>

          <div className="column">
            <div className="footer_titles">Services</div>
            <ul>
              <li>
                <Link to="/products">Product Engineering</Link>
              </li>
              {/* <li>
                <Link to="/products">accounting</Link>
              </li> */}
              <li>
                <Link to="/products">Vendor Management</Link>
              </li>
              <li>
                <Link to="/products">Project Management</Link>
              </li>
              <li>
                <Link to="/products">Smart Scheduling</Link>
              </li>
              <li>
                <Link to="/products">Sales CRM</Link>
              </li>
              <li>
                <Link to="/products">Inventory Control</Link>
              </li>
            </ul>
          </div>

          <div className="column">
            <ul>

              <li>
                <Link to="/products">Cusomer Management</Link>
              </li>
              <li>
                <Link to="/products">Quality Control</Link>
              </li>
              <li>
                <Link to="/products">Employee Management</Link>
              </li>
              <li>
                <Link to="/products">Production Planning</Link>
              </li>
              <li>
                <Link to="/products">Rest API</Link>
              </li>
              <li>
                <Link to="/products">Reports/Indicators</Link>
              </li>
            </ul>
          </div>
          {/* <div className="column">
            <div className="footer_titles">SERVICES</div>
          </div> */}
          <div className="column">
            <div className="footer_titles"><Link to="/aboutus">ABOUT US</Link></div>
          </div>
          <div className="column" id="contactus_btn_column">
            <span id="footer_btn"><Link to="/contact">contact us</Link></span>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div>Ⓒ 2022 Edata+ systems. All rights reserved.</div>
      </div>
    </div>
  );
}

export default Footer;
