import React from "react";
import useWindowDimensions from "../utils/useWindowDimensions";
import "./Products.css"; // the styles used in this page are from produts.css page

import product_engineering from "../images/product_engineering.png";
import accounting from "../images/accounting.png";
import vendor_management from "../images/vendor_management.png";
import project_management from "../images/project_management.png";
import smart_scheduling from "../images/smart_scheduling.png";
import sales_crm from "../images/sales_crm.png";
import inventory_control from "../images/inventory_control.png";
import customer_management from "../images/customer_management.png";
import quality_control from "../images/quality_control.png";
import employee_management from "../images/employee_management.png";
import production_planning from "../images/production_planning.png";
import rest_api from "../images/rest_api.png";
import reports_indicators from "../images/reports_indicators.png";

const Products = () => {
  const { height, width } = useWindowDimensions();
  return (
    // <div style={{ height: height }}>
    <div className="main1">
      {/* cover image */}
      <div className="productcover_image"></div>
      {/*main  Heading /title of the page */}
      <div className="ouproductsdiv">
        <div className="ourproducts">Our Services</div>
      </div>

      {/*div for dispalying all our products  */}
      <div className="productsmaindiv">
        {/*div for first product  */}
        <div className="productonediv">
          {/*Name of the product*/}
          <div className="productonetitle">Product Engineering</div>
          {/*div to display both the image and description of hte product side by side */}
          <div className="imgtext">
            {/*image of the product*/}
            <div className="product-image-container">
              <img
                src={product_engineering}
                alt="product_engineering"
                className="productimg"
              />
            </div>

            {/*description of the product*/}
            <div className="productoneimgtext">
              Take your computer-aided design (CAD) files and turn them into
              BOMs and RFQs, saving engineering staff time and helping to really
              reduce data entry and one-off production errors, which can be
              fairly significant.
            </div>
          </div>
        </div>

        {/* <div className="productonediv" id="accounting">
          <div className="productonetitle">Accounting</div>
          <div className="imgtext">
            <div className="product-image-container">
              <img src={accounting} alt="accounting" className="productimg" />
            </div>

            <div className="productoneimgtext">
              With just one system, connect finance, production and all payment
              information with a specific job to facilitate tracking and
              delivering results based on graphical data, faithfully showing how
              the operation is going.
            </div>
          </div>
        </div> */}

        <div className="productbackground">
          <div className="productonediv">
            <div className="productonetitle">Vendor Management</div>
            <div className="imgtext">
              <div className="productoneimgtext">
                Make pricing requests to numerous vendors for a variety of
                items. Combine orders or reorder items based on minimum stock,
                avoiding stock shortage while ensuring product quality and
                reliability.
              </div>
              <div className="product-image-container">
                <img
                  src={vendor_management}
                  alt="vendor_management"
                  className="productimg"
                />
              </div>
            </div>
          </div>

          <div className="productonediv">
            <div className="productonetitle">Project Management</div>
            <div className="imgtext">
              <div className="productoneimgtext">
                With smart purchasing, you can save time for your team and
                consistently achieve delivery deadlines with quality and
                consistency, even for long-lead products ahead of BOM.
              </div>
              <div className="product-image-container">
                <img
                  src={project_management}
                  alt="project_management"
                  className="productimg"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="productonediv">
          <div className="productonetitle">Smart Scheduling</div>
          <div className="imgtext">
            <div className="product-image-container">
              <img
                src={smart_scheduling}
                alt="smart_scheduling"
                className="productimg"
              />
            </div>

            <div className="productoneimgtext">
              With the only DBR scheduling technology intended for bespoke
              manufacturers, It is attainable to achieve and simplify shop floor
              scheduling and increase throughput for a more efficient and
              reliable registry record.
            </div>
          </div>
        </div>

        <div className="productonediv">
          <div className="productonetitle">Sales CRM</div>
          <div className="imgtext">
            <div className="product-image-container">
              <img src={sales_crm} alt="sales_crm" className="productimg" />
            </div>

            <div className="productoneimgtext">
              With all-in-one ERP and CRM support, you can track
              opportunities and monitor sales pipelines in real time anywhere,
              When you're in need of the precise information.
            </div>
          </div>
        </div>

        <div className="productbackground">
          <div className="productonediv">
            <div className="productonetitle">Inventory Control</div>
            <div className="imgtext">
              <div className="productoneimgtext">
                Avoid shortages of materials and long lead items by improving
                purchases and inventory control, being able to set up a rolling
                supply of goods when you need them.
              </div>
              <div className="product-image-container">
                <img
                  src={inventory_control}
                  alt="inventory_control"
                  className="productimg"
                />
              </div>
            </div>
          </div>

          <div className="productonediv">
            <div className="productonetitle">Customer Management</div>
            <div className="imgtext">
              <div className="productoneimgtext">
                Determine prices based on the material value, labour, and other
                similar costs. Start with accurate and strong quotes that will
                lead to an end with on-time delivery.
              </div>
              <div className="product-image-container">
                <img
                  src={customer_management}
                  alt="customer_management"
                  className="productimg"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="productonediv">
          <div className="productonetitle">Quality Control</div>
          <div className="imgtext">
            <div className="product-image-container">
              <img
                src={quality_control}
                alt="quality_control"
                className="productimg"
              />
            </div>

            <div className="productoneimgtext">
              Generate NCRs, monitor pre-emptive and corrective practices, and
              steadily build a solid and trustworthy set of standards. Hold
              vendors to quality standards and keep track of warranties.
            </div>
          </div>
        </div>
        <div className="productonediv">
          <div className="productonetitle">Employee Management</div>
          <div className="imgtext">
            <div className="product-image-container">
              <img
                src={employee_management}
                alt="employee_management"
                className="productimg"
              />
            </div>

            <div className="productoneimgtext">
              Identify true costs of managing payroll by organizing information
              through production order, taking care of employees' invoices and
              managing costs on the same platform.
            </div>
          </div>
        </div>
        <div className="productbackground">
          <div className="productonediv">
            <div className="productonetitle">Production Planning</div>
            <div className="imgtext">
              <div className="productoneimgtext">
                Maximize throughput based on real-time oversight of the
                production floor with reliable information, the actual capacity
                and the impact of the actions on the existing resources.
              </div>
              <div className="product-image-container">
                <img
                  src={production_planning}
                  alt="production_planning"
                  className="productimg"
                />
              </div>
            </div>
          </div>

          <div className="productonediv">
            <div className="productonetitle">Rest API </div>
            <div className="imgtext">
              <div className="productoneimgtext">
                Connect third-party applications, websites, and custom programs
                to share and capture real-time information about your products
                with dealers, suppliers and customers.
              </div>
              <div className="product-image-container">
                <img src={rest_api} alt="rest_api" className="productimg" />
              </div>
            </div>
          </div>
        </div>

        <div className="productonediv">
          <div className="productonetitle">Reports/Indicators </div>
          <div className="imgtext">
            <div className="product-image-container">
              <img
                src={reports_indicators}
                alt="reports_indicators"
                className="productimg"
              />
            </div>

            <div className="productoneimgtext">
              Monitor and measure operations using real-time dashboards with
              iterative graphs and informative data. Easily Track progress,
              efficiency, and employee performance.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
