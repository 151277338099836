import logo from "../images/EDATA_Logo_2022v14x.png";
import React, { useState, useEffect } from "react";
import { FiMenu, FiX } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(1);

  var location = useLocation().pathname;

  useEffect(() => {
    switch (location) {
      case "/":
      case "/home":
        setActive(1);
        break;

      case "/products":
        setActive(2);
        break;

      case "/mes":
        setActive(3);
        break;
        
      case "/aboutus":
        setActive(4);
        break;

      case "/contact":
        setActive(5);
        break;

      default:
        setActive(1);
        break;
    }
  }, []);

  return (
    <nav className="navbar">
      <nav className="navbar_content">
        <div className="img-wrapper">
          <img src={logo} className="logo" alt="Logo" />
        </div>

        <div onClick={() => setOpen(!open)} className="nav-icon">
          {open ? <FiX size={30} /> : <FiMenu size={30} />}
        </div>
        <ul className={open ? "nav-links active" : "nav-links"}>
          <li className="nav-item">
            <Link
              to="/"
              className={active === 1 ? "nav-link active" : "nav-link"}
              onClick={() => {
                setOpen(false);
                setActive(1);
              }}
            >
              Home
            </Link>
          </li>

          <li className="nav-item">
            <Link
              to="/products"
              className={active === 2 ? "nav-link active" : "nav-link"}
              onClick={() => {
                setOpen(false);
                setActive(2);
              }}
            >
              Products
            </Link>
          </li>

          <li className="nav-item">
            <Link
              to="/mes"
              className={active === 3 ? "nav-link active" : "nav-link"}
              onClick={() => {
                setOpen(false);
                setActive(3);
              }}
            >
              MES
            </Link>
          </li>

          <li className="nav-item">
            <Link
              to="/aboutus"
              className={active === 4 ? "nav-link active" : "nav-link"}
              onClick={() => {
                setOpen(false);
                setActive(4);
              }}
            >
              About Us
            </Link>
          </li>

          <li className="nav-item">
            <Link
              to="/contact"
              className={active === 5 ? "nav-link active" : "nav-link"}
              onClick={() => {
                setOpen(false);
                setActive(5);
              }}
            >
              Contact Us
            </Link>
          </li>
        </ul>
      </nav>
    </nav>
  );
};

export default Navbar;
