import React from "react";
import "./AboutUs.css";
// import "./Home.css";
import Personcomputerimg from "../images/computer_and_person.png";
import useWindowDimensions from "../utils/useWindowDimensions";
import { Link } from "react-router-dom";

import customer_icon from "../images/icons/customer_icon.png";
import innovative_icon from "../images/icons/innovative_icon.png";
import Integrity_icon from "../images/icons/Integrity_icon.png";
import quality_icon from "../images/icons/quality_icon.png";
import teamwork_icon from "../images/icons/teamwork_icon.png";
import trust_icon from "../images/icons/trust_icon.png";

const AboutUs = () => {
  const { height, width } = useWindowDimensions();
  return (
    <div className="main_div">
      <div className="cover_image_about"></div>

      <div className="about_div">
        <div className="header1">About Us</div>
      </div>

      <div className="split_div">
        <div className="child">
          <div className="child-text-container">
            <h1 className="header1">We are all about connection.</h1>

              EdataPlus, the MES that orchestrates people, processes, materials
              & machines, all to increase efficiency and drive throughput.
     
          </div>
        </div>
        <div className="child1">
          <img
            src={Personcomputerimg}
            alt="Person typing on computer"
            className="second-div-img"
          />
        </div>
      </div>

      <div className="quote1_div">
        <h1 className="header1">The values we live by.</h1>
        <br/>
      
          Save days on your operations and produce outcomes that help you and
          your customers succeed.
   
      </div>

      <div className="icon_div">
        <div className="rowdiv">
          <div className="columndiv">
            <img
              src={Integrity_icon}
              alt="icon image hand shake"
              className="gridimage"
            />
            <div className="grid-title">Integrity</div>
            <div className="about-grid-desc">
              In all we do, we uphold the greatest standards of integrity.
            </div>
          </div>
          <div className="columndiv">
            <img src={trust_icon} alt="tick mark" className="gridimage" />
            <div className="grid-title">Trust</div>
            <div className="about-grid-desc">
              We place a high value on honesty and trustworthiness.
            </div>
          </div>
          <div className="columndiv">
            <img src={quality_icon} alt="quality check" className="gridimage" />
            <div className="grid-title">Quality</div>
            <div className="about-grid-desc">
              We serve our customers with exceptional solutions and excellent
              service.
            </div>
          </div>
        </div>
        <div className="rowdiv">
          <div className="columndiv">
            <img
              src={innovative_icon}
              alt="bulb images"
              className="gridimage"
            />
            <div className="grid-title">Innovation</div>
            <div className="about-grid-desc">
              By encouraging curiosity and new ideas, we constantly strive to
              exceed expectations.
            </div>
          </div>
          <div className="columndiv">
            <img
              src={teamwork_icon}
              alt="group of people icon"
              className="gridimage"
            />
            <div className="grid-title">Teamwork</div>
            <div className="about-grid-desc">
              We collaborate across departments to suit our clients' demands and
              assist in their success.
            </div>
          </div>
          <div className="columndiv">
            <img
              src={customer_icon}
              alt="headphone icon"
              className="gridimage"
            />
            <div className="grid-title">Customer Dedication</div>
            <div className="about-grid-desc">
              We build relationships with our consumers that make a positive
              difference in their lives.
            </div>
          </div>
        </div>
      </div>

      <div className="goal_div">
        <h1 className="header1">Our Goal</h1>
        <p className="descreption">
          Is to supply software and expertise to assist clients on their
          digitalization journey, based on computer aided design, maintaining
          processes and information flows streamlined through integration
          solutions
        </p>
      </div>

      <div className="more_info_div">
        <h1 className="header1">Want to know more about MES?</h1>
        <div id="mes_link">
            <Link to={"/mes"}>read more</Link>
          </div>
      </div>
    </div>
  );
};

export default AboutUs;
